<div class="navbar-actions">
  <button
    mat-raised-button
    routerLink="/applications/new"
    class="split-button-left"
    matTooltip="Добави нова заявка"
  >
    <mat-icon>add</mat-icon>
  </button>
  <!-- <button mat-raised-button class="split-button-middle" [matMenuTriggerFor]="menu" matTooltip="Допълнителни опции">
        <mat-icon aria-hidden="false" aria-label="Example home icon">expand_more</mat-icon>
    </button> -->
  <button
    mat-raised-button
    [matMenuTriggerFor]="applicationMenu"
    class="split-button-right"
    matTooltip="Допълнителни опции"
  >
    <mat-icon aria-hidden="false" aria-label="Expand more icon"
      >expand_more</mat-icon
    >
  </button>
</div>

<mat-menu #applicationMenu="matMenu" backdropClass="tb-split-menu-menu">
  <div>
    <button
      *ngIf="showCopyRefferalLinkButton"
      matTooltip="Копирай"
      matTooltipPosition="left"
      mat-menu-item
      (click)="copyRefferalLink()"
    >
      <mat-icon>content_copy</mat-icon>
      Линк за заявка
    </button>
    <button mat-menu-item routerLink="/applications/new">
      <mat-icon>add</mat-icon>
      Създай заявка
    </button>
    <button
      *ngIf="showInvitationLink"
      mat-menu-item
      routerLink="/applications/referal"
    >
      <mat-icon>send</mat-icon>
      Изпрати покана
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item routerLink="/profile">
      <mat-icon>account_circle</mat-icon>
      <span translate="global.profile"></span>
    </button>
    <button mat-menu-item routerLink="/profile/devices">
      <mat-icon>verified</mat-icon>
      <span translate="global.devices"></span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span translate="global.logout"></span>
    </button>
  </div>
</mat-menu>
