import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CustomFormFieldBase } from 'src/app/components/shared/dynamic-form/dynamic-form-control-types/custom-form-field.type';
import {
  ProductPeriodUnit,
  ProductPlan,
} from 'src/app/types/product-plan.type';

@Component({
  selector: 'itfg-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        subscriptSizing: 'dynamic',
      },
    },
  ],
})
export class DynamicFormFieldComponent {
  @Input() formField!: CustomFormFieldBase<string>;
  @Input() form!: FormGroup;

  get isValid() {
    return this.form.controls[this.formField.key].valid;
  }

  onProductPlanChanged(productPlan: ProductPlan) {
    if (!productPlan) {
      return;
    }
    const period: number =
      productPlan.product.periodUnit === ProductPeriodUnit.MONTH
        ? productPlan.installmentsNumber
        : productPlan.installmentDays;
    const principal = productPlan.principal;
    this.form.patchValue({
      product: productPlan.product.code,
      period,
      principal,
      product_id: productPlan.product.id,
    });
  }

  onFileSelected(file: File, formControlName: string) {
    this.form.get(formControlName).patchValue(file);
  }

  displayFn(option: { key: string; value: string }): string {
    return option.value;
  }

  displayFnAutocomplete(option: { id: string; name: string }) {
    return option && option.name;
  }
}
