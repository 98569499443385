import { CreditInstallment } from "./credit.type";

export enum InstallmentStatus {
    OVERDUE = 'OVERDUE',
    PAID = 'PAID',
    INCOMING = 'INCOMING'
}

export function calculateInstallmentStatus(installment: CreditInstallment): InstallmentStatus {
    if (installment.paymentDate) {
        return InstallmentStatus.PAID;
    }

    const today = new Date().setHours(0, 0, 0, 0);
    const maturityDate = new Date(installment.maturityDate).setHours(0, 0, 0, 0);

    if (maturityDate < today) {
        return InstallmentStatus.OVERDUE;
    }

    return InstallmentStatus.INCOMING;
}

export function getCurrentInstallment(installments: CreditInstallment[]): CreditInstallment | null {
    if (!installments.length) return null;

    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    const currentMonthInstallment = installments.find(installment => {
        const maturityDate = new Date(installment.maturityDate);
        return maturityDate.getMonth() === currentMonth &&
            maturityDate.getFullYear() === currentYear;
    });

    if (currentMonthInstallment) {
        return currentMonthInstallment;
    }

    return currentMonthInstallment || installments[installments.length - 1];
}