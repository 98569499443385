import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  public serializeObjectToQueryString(objectToSerialize: any): string {
    const params = new URLSearchParams();

    const keys = Object.keys(objectToSerialize);

    keys.forEach((key, index, array) => {
      params.set(key, objectToSerialize[key]);
    });

    return params.toString();
  }

  public parseFullName(fullName: string): {
    firstName: string;
    middleName: string;
    lastName: string;
  } {
    const namesArr = fullName.split(' ').filter((s: string) => s.length !== 0);
    const allThreePartsPresent = namesArr.length === 3;
    let firstName = namesArr.shift() || null;
    let middleName = (allThreePartsPresent ? namesArr.shift() : null) || null;
    let lastName = namesArr.shift() || null;
    return {
      firstName,
      middleName,
      lastName,
    };
  }

  public parseErrorMessage(res: HttpErrorResponse): string {
    const messages: string[] = [];
    let message = 'Опс, нещо се обърка!';
    if (Array.isArray(res.error)) {
      if (res.error.some((e) => e.message === "UnauthorizedException")) {
        return 'errors.UnauthorizedException';
      } else {
        res.error.forEach((e) => {
          messages.push(e.message);
        });
        message = messages.join('\r\n');
      }
    } else if (
      typeof res.error === 'object' &&
      res.error.hasOwnProperty('message')
    ) {
      message = res.error.message;
    }
    return `errors.${message}`;
  }

  public fieldDependsOn(
    dependentControlKey: string,
    dependeeControlKeys: string[],
    validationKey: string
  ) {
    return (group: UntypedFormGroup): ValidationErrors => {
      const errorFields: string[] = [];

      if (Validators.required(group.controls[dependentControlKey]) !== null) {
        // If field is empty we don't have to check anything
        return null;
      } else {
        dependeeControlKeys.forEach((item: string) => {
          if (Validators.required(group.controls[item]) !== null) {
            errorFields.push(item);
          }
        });
      }

      if (errorFields.length === 0) {
        return null;
      } else {
        const validationError: any = {};
        validationError[validationKey] = {
          value: {
            dependentControlKey: dependentControlKey,
            dependeeControlKeys: dependeeControlKeys,
            errorFields: errorFields,
          },
        };
        return validationError;
      }
    };
  }
}
