import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toCustomItfgCurrency',
})
export class ToCustomItfgCurrencyPipe extends CurrencyPipe implements PipeTransform {
  override transform(
    number?: number | string,
    currencyCode: string = 'BGN',
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo: string = '1.2-2',
    locale: string = 'bg' // Default to Bulgarian locale
  ): any {
    if (number === null || number === undefined || isNaN(Number(number))) {
      return null;
    }

    const result = super.transform(Number(number), currencyCode, display, digitsInfo, locale);

    if (!result) return '';

    const parts = result.split(',');
    const integerPart = parts[0].replace(/[^0-9]/g, '');

    const formattedIntegerPart = integerPart.length >= 5
      ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      : integerPart;

    const decimalPart = parts[1] ? `,${parts[1]}` : '';

    return `${formattedIntegerPart}${decimalPart}`;
  }
}
