export const environment = {
    envName: 'dev',
    production: false,
    CREDITS_API_URL: 'https://credits.staging.aws.smilecredit.bg/',
    // CREDITS_API_URL: 'http://localhost:8080/',
    CLIENT_COMMUNICATION_API_URL: 'https://client-communication.staging.aws.smilecredit.bg/',
    // CLIENT_COMMUNICATION_API_URL: 'http://localhost:8081/',
    WEBSITE_SC_URL: 'https://staging.aws.smilecredit.bg/',
    WEBSITE_MF_URL: 'https://staging.medifin.bg/',
};
