import { Credit } from "./application.type";
import { Product } from "./product-plan.type";
import { UserDetails } from "./user.type";

export enum InvitationStatusType {
    NEW = 'NEW',
    OPENED = 'OPENED',
    COMPLETED = 'COMPLETED'
}

export class Invitation {
    id: number;
    principal: number;
    email: string;
    product: {
        id: number;
        brand: {
            id: number;
            name: string;
            code: string;
            active: boolean;
        };
        active: boolean;
        isPublic: boolean;
        name: string;
        code: string;
        productPeriod: {
            id: number;
            unit: string;
            period: number;
        };
        apr: number;
        utilizationFeePercent: number;
        forfeitAccrualChunks: number;
        earlyRepaymentFee: boolean;
        loyaltyEnabled: boolean;
        public: boolean;
    };
    status: {
        id: number;
        name:  InvitationStatusType;
    };
    partner: {
        id: number;
        code: string;
        name: string;
        phone: string;
        email: string;
        emailConfirmed: boolean;
        employerId: string;
        active: boolean;
        iban: string;
        representative: string;
        accountingCode: string | null;
        permanentAddress: string;
        presentAddress: string;
        twoFactorEnabled: boolean;
    };
    description: string;
    credit?: Credit;
    createdAt: Date;
    invitationTokens: any[];

    constructor(partial: Partial<Invitation>) {
        Object.assign(this, partial);
    }
}