import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { UserDetails, UserDocumentType } from '../types/user.type';
import { Observable, map } from 'rxjs';
import {
  ApplicationParsedView,
  ApplicationRaw,
  BrandCodes,
  CreateApplicationDto,
} from '../types/application.type';
import {
  CountryType,
  CreditDocument,
  CreditInstallment,
  EducationType,
  EmploymentType,
} from '../types/credit.type';
import { Product, ProductPlan } from '../types/product-plan.type';
import { AuthService } from './auth.service';
import { Invitation, InvitationStatusType } from '../types/invitation.type';
import { Page } from '../types/page';

@Injectable({
  providedIn: 'root',
})
export class CreditsService {
  constructor(
    private request: RequestService,
    private auth: AuthService,
  ) {
  }

  profileDetails$(): Observable<UserDetails> {
    return this.request.get(['partner', 'profile']);
  }

  // POST/partner/profile
  editProfileDetails$(profile: UserDetails) {
    return this.request.post(['partner', 'profile'], {
      body: profile,
    });
  }

  fetchProducts$(brand = BrandCodes.SC): Observable<Product[]> {
    return this.request.get(['partner', 'products', brand]);
  }

  fetchProductPlans$(brand = BrandCodes.SC): Observable<ProductPlan[]> {
    return this.request.get(['partner', 'products', brand, 'plans']);
  }

  // APLICATIONS
  fetchApplication$(options: any): Observable<ApplicationParsedView> {
    return this.request
      .get(['partner', 'applications'], options)
      .pipe(
        map((applicationRaw: ApplicationRaw[]) => this.rawApplicationParser(applicationRaw[0]))
      );
  }

  // - GET /partner/applications/credit/{id}/public
  fetchApplicationPublic$(creditId: number, token: string): Observable<ApplicationParsedView> {
    return this.request
      .get(['partner', 'applications', 'credit', creditId, 'public'], {
        params: {
          token,
        }
      })
      .pipe(
        map(this.rawApplicationParser.bind(this))
      );
  }

  createApplication$(
    dto: CreateApplicationDto
  ): Observable<ApplicationParsedView> {
    return this.request
      .post(['partner', 'applications', 'short'], {
        body: dto,
      })
      .pipe(
        map(this.rawApplicationParser.bind(this))
      );
  }

  rawApplicationParser(raw: ApplicationRaw): ApplicationParsedView {
    const parsed: ApplicationParsedView = new ApplicationParsedView(raw);
    if (!!this.auth.currentUser) {
      parsed.applyUserSpecificMapping(this.auth.currentUser);
    }
    return parsed;
  }

  confirmApplication$(applicationId: number, token: string) {
    return this.request.post(
      ['partner', 'applications', applicationId, 'confirm'],
      {
        body: {
          applicationId,
          token,
        },
      }
    );
  }

  rejectApplication$(applicationId: number, token: string) {
    return this.request.post(
      ['partner', 'applications', applicationId, 'reject'],
      {
        body: {
          applicationId,
          token,
        },
      }
    );
  }

  confirmApplicationPublic$(creditId: number, token: string) {
    return this.request.post(
      ['partner', 'applications', creditId, 'confirm', 'public'],
      {
        body: {
          applicationId: creditId,
          token,
        },
      }
    );
  }

  rejectApplicationPublic$(creditId: number, token: string) {
    return this.request.post(
      ['partner', 'applications', creditId, 'reject', 'public'],
      {
        body: {
          applicationId: creditId,
          token,
        },
      }
    );
  }

  fetchApplications$({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }): Observable<ApplicationParsedView[]> {
    return this.request
      .get(['partner', 'applications'], {
        params: {
          from: startDate.format('YYYY-MM-DD'),
          to: endDate.format('YYYY-MM-DD'),
        },
      })
      .pipe(
        map((applicationRawList: ApplicationRaw[]): ApplicationParsedView[] => {
          const parsed: ApplicationParsedView[] = [];
          applicationRawList.map((applicationRaw: ApplicationRaw) => {
            parsed.push(this.rawApplicationParser(applicationRaw));
          });
          return parsed;
        })
      );
  }

  fetchCreditDocuments$(
    creditId: number | string
  ): Observable<CreditDocument[]> {
    return this.request.get(['partner', 'applications', creditId, 'documents']);
  }

  fetchCreditInstallments$(
    creditId: number | string
  ): Observable<CreditInstallment[]> {
    return this.request.get(['partner', 'applications', creditId, 'installments']);
  }

  // - GET /partner/applications/{creditId}/documents/public
  fetchCreditDocumentsPublic$(
    creditId: number | string,
    token: string,
  ): Observable<CreditDocument[]> {
    return this.request.get(['partner', 'applications', creditId, 'documents', 'public'], {
      params: {
        token,
      }
    });
  }


  getCreditDocumentPdf$(creditId: number, documentId: number): Observable<any> {
    return this.request.get(
      ['partner', 'applications', creditId, 'documents', documentId, 'pdf'],
      { responseType: 'blob', headers: { Accept: 'application/pdf' } }
    );
  }

  // - GET /{creditId}/documents/{documentId}/pdf/public
  getCreditDocumentPdfPublic$(creditId: number, documentId: number, token: string): Observable<any> {
    return this.request.get(
      ['partner', 'applications', creditId, 'documents', documentId, 'pdf', 'public'],
      {
        responseType: 'blob',
        headers: { Accept: 'application/pdf'},
        params: { token },
      }
    );
  }

  addApplicationTags$(applicationId: number, tags: string[]) {
    return this.request.post(['partner', 'applications', applicationId, 'tags'], {
      body: tags
    });
  }


  uploadCreditDocumentByType$(
    creditId: number,
    documentType: UserDocumentType,
    document: FormData
  ) {
    return this.request.post(
      ['partner', 'applications', 'credit-id-documents', creditId, documentType],
      {
        body: document,
      }
    );
  }

  // - POST /credit-id-documents/{creditId}/{type}/public
  uploadCreditDocumentByTypePublic$(
    creditId: number,
    documentType: UserDocumentType,
    document: FormData,
    token: string,
  ) {
    return this.request.post(
      ['partner', 'applications', 'credit-id-documents', creditId, documentType, 'public'],
      {
        body: document,
        params: { token }
      }
    );
  }


  uploadApplicationDocumentByType$(
    applicationId: number,
    documentType: UserDocumentType,
    document: FormData
  ) {
    return this.request.post(
      [
        'partner',
        'applications',
        'application-id-documents',
        applicationId,
        documentType,
      ],
      {
        body: document,
      }
    );
  }

  verifyTotpToken(code: string, rememberMe = false) {
    return this.request.post(['partner', 'auth', '2fa', 'verify'], {
      headers: {
        'Content-type': 'application/json'
      },
      withCredentials: rememberMe,
      params: {
        code,
        rememberMe
      }
    })
  }

  // Utils

  getEducationTypeList$(): Observable<EducationType[]> {
    return this.request.get(['partner', 'utils', 'education-types']);
  }

  getEmploymentTypeList$(): Observable<EmploymentType[]> {
    return this.request.get(['partner', 'utils', 'employment-types']);
  }

  getCountriesTypeList$(): Observable<CountryType[]> {
    return this.request.get(['partner', 'utils', 'countries']);
  }

  getPaymentMethodList$(brandCode: string): Observable<EducationType[]> {
    return this.request.get(['partner', 'utils', 'payment-methods', brandCode]);
  }

  fetchInvitationsList$(params: {
    status?: InvitationStatusType | Array<InvitationStatusType>;
    page?: number;
    pageSize?: number;
    direction?: string;
    column?: string;
  }): Observable<Page<Invitation>> {
    return this.request.get(['partner', 'invitations', 'search'], { params: params });
  }

  fetchInvitation$(invitationId: number | string): Observable<Invitation> {
    return this.request.get(['partner', 'invitations', invitationId]);
  }
}
