import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CreditInstallment } from 'src/app/types/credit.type';
import { calculateInstallmentStatus } from 'src/app/types/installment.type';

@Pipe({
  name: 'intallmentStatusTranslationPipe'
})
export class InstallmentStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(installment: CreditInstallment): string {
    const status = calculateInstallmentStatus(installment);
    return this.translate.instant(`installment.types.${status}`);
  }
}