<div mat-dialog-title>
    <span>Грешка</span>
</div>
<div mat-dialog-content>
    <span>
        <!-- За повече информация, моля свържете се с нас. -->
        {{ rawError.error.message || rawError.message }}
        <!-- За повече информация, моля <a class="no-href-style" href="">свържете се с нас.</a> -->
    </span>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button type="button" (click)="onClose()">
        {{ 'global.close' | translate }}
    </button>
</div>