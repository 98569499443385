<div mat-dialog-title>
    <span [innerHTML]="title"></span>
</div>
<div mat-dialog-content>
    <span [innerHTML]="message"></span>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button type="button" (click)="close(false)">
        {{ 'global.close' | translate | uppercase }}
   </button>
    <button color="primary" mat-stroked-button type="button" (click)="close(true)">
        <span>{{ acceptButtonLabel | translate | uppercase }}</span>
    </button>
</div>