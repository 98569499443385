// installment-status-icon.component.ts
import { Component, Input, OnChanges } from '@angular/core';
import { CreditInstallment } from 'src/app/types/credit.type';
import { InstallmentStatus, calculateInstallmentStatus } from 'src/app/types/installment.type';

interface StatusStyleConfig {
    icon: string;
    color: string;
}

const INSTALLMENT_STATUS_STYLE_MAP: Record<InstallmentStatus, StatusStyleConfig> = {
    PAID: {
        color: 'green',
        icon: 'check_circle_outline',
    },
    OVERDUE: {
        color: 'rgba(235, 88, 18, 0.82)',
        icon: 'warning',
    },
    INCOMING: {
        color: '#2196F3',
        icon: 'timelapse',
    }
};

@Component({
    selector: 'itfg-installment-status-icon',
    templateUrl: './application-installment-status-icon.component.html',
    styleUrls: ['./application-installment-status-icon.component.scss']
})
export class ApplicationInstallmentStatusIconComponent implements OnChanges {
    @Input() installment: CreditInstallment;

    public icon: string;
    public color: string;
    public currentStatus: InstallmentStatus;

    ngOnChanges() {
        this.currentStatus = calculateInstallmentStatus(this.installment);
        const style = INSTALLMENT_STATUS_STYLE_MAP[this.currentStatus];
        this.icon = style.icon;
        this.color = style.color;
    }
}