import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, finalize, tap } from 'rxjs';
import { WebHttpUrlEncodingCodec } from '../utils/webHttpUrlEncodingCodec';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  protected apiUrl: string;
  private http: HttpClient;
  onPostRequest: Subject<void> = new Subject<void>();

  constructor(http: HttpClient) {
    this.apiUrl = environment.CREDITS_API_URL;
    this.http = http;
  }

  set apIUrl(url: string) {
    if (url[url.length - 1] !== '/') {
      url += '/';
    }
    this.apiUrl = url;
  }

  get apIUrl() {
    return this.apiUrl;
  }

  makeRequest(
    method: string,
    route: (string | number)[],
    options?: Object
  ): Observable<any> {
    const path = route.join('/');
    let request$ = this.http.request(method, this.apiUrl + path, options || {});
    return request$;
  }

  get(route: (string | number)[], options?: any): Observable<any> {
    if (options && options.params) {
      let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() });
      const keyValuePair = Object.entries(options.params);
      keyValuePair.forEach(pair => {
        const key = pair[0];
        const value = pair[1];
        if (Array.isArray(value)) {
          value.forEach(entry => {
            params = params.append(key, entry);
          });
        } else {
          params = params.set(key, <string>value);
        }
      });
      options.params = params;
    }
    return this.makeRequest('GET', route, options);
  }

  post(route: (string | number)[], options?: any): Observable<any> {
    if (options && options.params) {
      let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() });
      const keyValuePair = Object.entries(options.params);
      keyValuePair.forEach((pair) => {
        params = params.set(pair[0], <string>pair[1]);
      });
      options.params = params;
    }
    return this.makeRequest('POST', route, options).pipe(
      tap({
        complete: () => {
          return this.onPostRequest.next();
        },
      })
    );
  }

  put(route: (string | number)[], options?: any): Observable<any> {
    if (options && options.params) {
      let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() });
      const keyValuePair = Object.entries(options.params);
      keyValuePair.forEach((pair) => {
        params = params.set(pair[0], <string>pair[1]);
      });
      options.params = params;
    }
    return this.makeRequest('PUT', route, options).pipe(
      tap({
        complete: () => {
          return this.onPostRequest.next();
        },
      })
    );
  }

  delete(route: (string | number)[], options?: Object): Observable<any> {
    return this.makeRequest('DELETE', route, options);
  }

}
