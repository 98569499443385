import { Pipe, PipeTransform } from '@angular/core';
import { Invitation } from 'src/app/types/invitation.type';

@Pipe({
  name: 'qrCodeTooltip'
})
export class QrCodeTooltipPipe implements PipeTransform {
  transform(invitation: Invitation): string {
    if (invitation?.invitationTokens?.length) {
      return 'Виж QR код';
    } else {
      return 'QR кодът не е наличен';
    }
  }
}