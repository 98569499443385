<div class="slider-container">
  <mat-card>
    <mat-card-content>
      <mat-form-field>
        <mat-label>{{ "global.product" | translate }}</mat-label>
        <mat-select
          [(value)]="product"
          placeholder="{{ 'global.product' | translate }}"
          required
        >
          <mat-option *ngFor="let product of productList" [value]="product">{{
            product.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="calculator-content">
        <div class="slider-label-container">
          <ng-container *ngIf="!isPrincipalEditing; else principalInput">
            <label
              class="slider-value-label principal"
              (click)="startPrincipalEdit()"
            >
              {{ allowedPrincipalList[selectedPrincipalIndex] | toCustomItfgCurrency }}
            </label>
          </ng-container>

          <ng-template #principalInput>
            <div class="input-with-unit">
              <input
                #principalInputRef
                type="number"
                [ngModel]="allowedPrincipalList[selectedPrincipalIndex]"
                (keydown.enter)="
                  $event.preventDefault(); finishPrincipalEdit($event)
                "
                (blur)="finishPrincipalEdit($event)"
                class="editable-input"
              />

              <span class="unit-symbol">лв.</span>
            </div>
          </ng-template>
        </div>

        <div class="flex-layout-row">
          <button
            mat-icon-button
            type="button"
            (click)="principalStepDown()"
            [disabled]="selectedPrincipalIndex <= 0"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <mat-slider
            [min]="0"
            [max]="allowedPrincipalList.length - 1"
            [step]="1"
            (input)="onPrincipalInputChange()"
          >
            <input
              (dragEnd)="setTargetPlan()"
              matSliderThumb
              [(ngModel)]="selectedPrincipalIndex"
              #principalSlider
            />
          </mat-slider>
          <button
            mat-icon-button
            type="button"
            (click)="principalStepUp()"
            [disabled]="
              selectedPrincipalIndex >= allowedPrincipalList.length - 1
            "
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <ng-container *ngIf="viewSettings.showPeriodSlider">
          <div class="slider-label-container">
            <ng-container *ngIf="!isPeriodEditing; else periodInput">
              <label
                class="slider-value-label period"
                (click)="startPeriodEdit()"
              >
                {{ periodsList[selectedPeriodIndex] }}&nbsp;{{
                  product.periodUnit === "MONTH" ? "месеца" : "дни"
                }}
              </label>
            </ng-container>

            <ng-template #periodInput>
              <div class="input-with-unit">
                <input
                  #periodInputRef
                  type="number"
                  [ngModel]="periodsList[selectedPeriodIndex]"
                  (keydown.enter)="
                    $event.preventDefault(); finishPeriodEdit($event)
                  "
                  (blur)="finishPeriodEdit($event)"
                  class="editable-input"
                />

                <span class="unit-label">
                  {{ product.periodUnit === "MONTH" ? "месеца" : "дни" }}
                </span>
              </div>
            </ng-template>
          </div>

          <div class="flex-layout-row">
            <button
              mat-icon-button
              type="button"
              (click)="periodStepDown()"
              [disabled]="selectedPeriodIndex <= 0"
            >
              <mat-icon>remove</mat-icon>
            </button>
            <mat-slider [min]="0" [max]="periodsList.length - 1" [step]="1">
              <input
                (valueChange)="onPeriodInputChange()"
                matSliderThumb
                [(ngModel)]="selectedPeriodIndex"
                #periodSlider
              />
            </mat-slider>
            <button
              mat-icon-button
              type="button"
              (click)="periodStepUp()"
              [disabled]="selectedPeriodIndex >= periodsList.length - 1"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
    <mat-card-footer *ngIf="viewSettings.showSummaryFooter">
      <mat-card-actions>
        <div class="calculator-plan-values-container">
          <ng-container
            *ngTemplateOutlet="
              calculatorPlanValue;
              context: {
                title: 'Вноска',
                value: productPlanSummary.installmentAmount
              }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              calculatorPlanValue;
              context: {
                title: 'Общо оскъпяване',
                value: productPlanSummary.totalCostIncrease
              }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              calculatorPlanValue;
              context: {
                title: 'Сума за връщане',
                value: productPlanSummary.totalReturnAmount
              }
            "
          ></ng-container>
        </div>
      </mat-card-actions>
    </mat-card-footer>
  </mat-card>
</div>

<ng-template #calculatorPlanValue let-title="title" let-value="value">
  <div class="calculator-plan-values-box">
    <label class="installment-amount-label">{{ title }}&nbsp;</label>
    <span class="installment-amount-label-value">{{ value | toCustomItfgCurrency }}</span>
  </div>
</ng-template>
