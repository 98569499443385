import { NgModule, LOCALE_ID, forwardRef, DEFAULT_CURRENCY_CODE, isDevMode, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthModule } from './components/auth/auth.module';
import { LoadingInterceptor } from './services/loading.interceptor';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  // subscriptSizing: 'dynamic'
};
import { NavSplitButtonComponent } from './components/nav-split-button/nav-split-button.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import {
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { registerLocaleData } from '@angular/common';
import localeBg from '@angular/common/locales/bg';
import { MatPaginatorIntlLocalizedService } from './services/mat-paginator-intl-localized.service';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';
import { EmbeddedNotificationComponent } from './components/embedded-notification/embedded-notification.component';
import { MatCardModule } from '@angular/material/card';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ErrorDialogComponent } from './components/shared/error-dialog/error-dialog.component';
import { InfoDialogComponent } from './components/shared/info-dialog/info-dialog.component';
import { PromptDialogComponent } from './components/shared/prompt-dialog/prompt-dialog.component';
import { environment } from 'src/environments/environment';
import { HomeScreenPromptComponent } from './components/home-screen-prompt/home-screen-prompt.component';
import { StorageService } from './services/storage.service';
import { StyleManager } from './components/shared/style-manager';
import { ThemeService } from './services/theme.service';
import { SharedModule } from './components/shared/shared.module';
import { DOMAIN_CONFIG_TOKEN, getDomainBasedAppConfig } from './config/app.config';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { PepDisclaimerComponent } from './components/public/pep/pep-disclaimer.component';
import { ConfirmDialogComponent } from './components/shared/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeBg);

export const DateFormats = {
  parse: {
    dateInput: ['YYYY-MM-DD']
  },
  display: {
    dateInput: 'DD.MM.YYYY г.',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function startupServiceFactory(themeService: ThemeService): Function {
  return () => themeService.loadTheme();
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingIndicatorComponent,
    NavSplitButtonComponent,
    BreadcrumbsComponent,
    EmbeddedNotificationComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    PromptDialogComponent,
    HomeScreenPromptComponent,
    PepDisclaimerComponent,
    ConfirmDialogComponent,
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
  imports: [
    AuthModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'bg',
    }),
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatMenuModule,
    MatSnackBarModule,
    MatCardModule,
    MatCheckboxModule,
    FormsModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [ThemeService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'bg',
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    {
      provide: LOCALE_ID,
      useValue: 'bg'
    },
    {
      provide: MatPaginatorIntl,
      useClass: forwardRef(() => MatPaginatorIntlLocalizedService)
    },
    {
      provide: DOMAIN_CONFIG_TOKEN,
      useFactory: () => getDomainBasedAppConfig(window.location.host),
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'BGN'
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule { }
