import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { cyrLatMap } from './cyrillic-latin-char-map';

export function isValidIban(control: AbstractControl): ValidationErrors | null {
  const ibanMatchPattern = /^BG[0-9]{2}[A-Z]{4}[0-9]{6}[A-Z0-9]{8}$/.test(
    control.value
  );
  if (!control.value || control.value.length === 0) {
    return null;
  }
  return ibanMatchPattern ? null : { invalidCharacters: { value: control } };
}

export function isValidEgn(control: AbstractControl): ValidationErrors | null {
  const EGN_WEIGHTS = [2, 4, 8, 5, 10, 9, 7, 3, 6];

  if (!control.value) {
    return null;
  }

  if (control.value.length !== 10) {
    return { incorrectEgnFormat: true };
  }

  const year = Number(control.value.substr(0, 2));
  const mon = Number(control.value.substr(2, 2));
  const day = Number(control.value.substr(4, 2));

  if (mon > 40) {
    if (!isValidDate(day, mon - 40, year + 2000)) {
      return { incorrectEgnFormat: true };
    }
  } else if (mon > 20) {
    if (!isValidDate(day, mon - 20, year + 1800)) {
      return { incorrectEgnFormat: true };
    }
  } else {
    if (!isValidDate(day, mon, year + 1900)) {
      return { incorrectEgnFormat: true };
    }
  }

  const checkSum = Number(control.value.substr(9, 1));
  let egnSum = 0;
  for (let i = 0; i < 9; i++) {
    egnSum += Number(control.value.substr(i, 1)) * EGN_WEIGHTS[i];
  }

  let validCheckSum = egnSum % 11;
  validCheckSum %= 10;
  if (validCheckSum === 10) {
    validCheckSum = 0;
  }

  if (checkSum !== validCheckSum) {
    return { incorrectEgnFormat: true };
  }

  return null;
}

export function isValidIdEmail(
  control: AbstractControl
): ValidationErrors | null {
  // tslint:disable-next-line:max-line-length
  const EMAIL_REGEXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (
    control.value !== null &&
    control.value !== '' &&
    (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))
  ) {
    return { incorrectMailFormat: true };
  }
  return null;
}

function isValidDate(d: number, m: number, y: number) {
  m = parseInt(m.toString(), 10) - 1;
  return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(m, y);
}

function daysInMonth(m: any, y: number) {
  switch (m) {
    case 1:
      return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
}

export class PasswordValidator {
  static confirmed = (controlName: string, matchingControlName: string) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const input = control.get(controlName);
      const matchingInput = control.get(matchingControlName);

      if (input === null || matchingInput === null) {
        return null;
      }

      if (
        matchingInput?.errors &&
        !matchingInput.errors['confirmedValidator']
      ) {
        return null;
      }

      if (input.value !== matchingInput.value) {
        matchingInput.setErrors({ confirmedValidator: true });
        return { confirmedValidator: true };
      } else {
        matchingInput.setErrors(null);
        return null;
      }
    };
  };
}

//

export function isValidIdCard(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  let value = control.value.toUpperCase();

  // Convert Cyrillic to Latin
  for (const [cyrillic, latin] of Object.entries(cyrLatMap)) {
    value = value.replace(new RegExp(cyrillic, 'g'), latin);
  }

  // Check for remaining Cyrillic
  const hasUnmappableCyrillic = /[А-Яа-я]/.test(value);
  if (hasUnmappableCyrillic) {
    return {
      invalidIdCardFormat: {
        invalidChars: true,
      },
    };
  }

  // Validate pattern
  const isValidPattern = /^[A-Z0-9]{2}\d{7}$/.test(value);
  if (!isValidPattern) {
    return {
      invalidIdCardFormat: {
        invalidPattern: true,
      },
    };
  }

  // Update if transformed
  if (value !== control.value) {
    control.setValue(value, { emitEvent: false });
  }

  return null;
}
